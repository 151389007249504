import i18n from "./translations/translationService";

class URLService {

	static generateRedirectUrl = (url: string) => {

		return (url.includes('?'))
			? `${url}&language=${i18n.language}`
			: `${url}?language=${i18n.language}`;
	};
}

export default URLService;
