import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BagstageLogo } from "../assets/bagstage_logo.svg";
import { ReactComponent as BarChartIcon } from "../assets/icons/bagstage_analyst.svg";
import { ReactComponent as ArrivalIcon } from "../assets/icons/bagstage_black_flight_landing.svg";
import { ReactComponent as TakeOffIcon } from "../assets/icons/bagstage_flight_takeoff.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/bagstage_operator.svg";
import { ReactComponent as QTBMonitorIcon } from "../assets/icons/bagstage_qtb_monitor.svg";
import { ReactComponent as TransferIcon } from "../assets/icons/bagstage_swap_horizontal.svg";
import Footer from "../components/homepage/Footer";
import HomePageCard from "../components/homepage/HomePageCard";
import LanguageSelectionTooltip from "../components/homepage/LanguageSelectionTooltip";
import UserProfile from "../components/homepage/UserProfile";
import { cardListItem } from "../components/UI/Cards/CardListItem";
import PageWrapper from "../components/UI/PageWrapper";
import StyledIcon from "../components/UI/StyledIcon";
import URLService from "../service/urlService";
import UserService from "../service/userService";
import { useAppSelector } from "../store/hooks";
import homePageStyles from "../styles/homePage.module.css";
import iconStyles from "../styles/icon.module.css";
import { KeycloakUserTokenType } from "../types/user.types";
import { bagstageApplications } from "../utils/commonUtil";

export interface BagstageUserDetails {
  userInitials: string;
  userName: string;
  userEmail: string;
}

export default function HomePage() {
  const { t } = useTranslation();
  const { isLogin, kcObject } = useAppSelector(
    (state) => state.authenticationSlice
  );
  const [bagstageUser, setBagstageUser] = useState<BagstageUserDetails>();

  const arrivalLinks: cardListItem[] = [
    {
      appRef: bagstageApplications.arrivalOperator,
      applicationTitle: t("operator-tool"),
      redirectUrl: URLService.generateRedirectUrl(
        window._env_.REACT_APP_ARRIVAL_OPERATOR_URL
      ),
      icon: (
        <StyledIcon icon={<GlobeIcon />} classes={iconStyles.globeIconBorder} />
      )
    },
    {
      appRef: bagstageApplications.arrivalAnalyst,
      applicationTitle: t("analyst-tool"),
      redirectUrl: URLService.generateRedirectUrl(
        window._env_.REACT_APP_ARRIVAL_ANALYST_URL
      ),
      icon: (
        <StyledIcon
          icon={<BarChartIcon />}
          classes={iconStyles.barChartIconBorder}
        />
      )
    }
    // {
    //   appRef: bagstageApplications.arrivalViewOnlyOperator,   // TODO: uncomment it when view only operator needs to be shown
    //   applicationTitle: t("view-only-operator-tool"),
    //   redirectUrl: "/",
    //   icon: (
    //     <StyledIcon
    //       icon={<ViewOnlyIcon />}
    //       classes={iconStyles.viewOnlyIconBorder}
    //     />
    //   )
    // }
  ];

	const departureLinks: cardListItem[] = [{

		appRef: bagstageApplications.departureAnalyst,
		applicationTitle: t("analyst-tool"),
		redirectUrl: URLService.generateRedirectUrl(
			window._env_.REACT_APP_DEPARTURE_ANALYST_URL 
				+ "?access_token=" 
				+ kcObject?.token
		),
		icon: (
			<StyledIcon
				icon={<BarChartIcon />}
				classes={iconStyles.barChartIconBorder}
			/>
		)
	}];

  const transferLinks: cardListItem[] = [
    {
      appRef: bagstageApplications.transferQtb,
      applicationTitle: t("qtb-monitor"),
      redirectUrl: URLService.generateRedirectUrl(
        window._env_.REACT_APP_TRANSFER_QTB_URL
      ),
      icon: (
        <StyledIcon
          icon={<QTBMonitorIcon />}
          classes={iconStyles.qtbMonitorIconBorder}
        />
      )
    }
  ];

  useEffect(() => {
    if (kcObject?.token && isLogin) {
      (async () => {
        const userData: KeycloakUserTokenType =
          (await kcObject?.loadUserInfo()) as KeycloakUserTokenType;
        if (userData) {
          setBagstageUser({
            userInitials: UserService.getUserInitials(userData),
            userName: UserService.getUsername(userData),
            userEmail: userData.preferred_username ?? ""
          });
        }
      })();
    }
  }, [kcObject, isLogin]);

  return (
    <PageWrapper>
      <header className={homePageStyles.headerSection}>
        <BagstageLogo className={homePageStyles.bagstageLogo} />
        <div className="flex gap-spacing-2xl items-center">
          <LanguageSelectionTooltip />
          {bagstageUser && <UserProfile bagstageUser={bagstageUser} />}
        </div>
      </header>

      <section className={homePageStyles.cardSection}>
        <HomePageCard
          icon={<ArrivalIcon />}
          title={t("arrival")}
          cardItems={arrivalLinks}
        />

        <HomePageCard
          icon={<TakeOffIcon />}
          title={t("departure")}
          cardItems={departureLinks}
        />

        <HomePageCard
          icon={<TransferIcon />}
          title={t("transfer")}
          cardItems={transferLinks}
        />
      </section>

      <Footer isLoggedIn={isLogin} />
    </PageWrapper>
  );
}
